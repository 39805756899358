<template>
  <div>
  <b-card
    class="warehouserelation-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehouserelationForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="仓库ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              v-model="this.id"
              readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
        <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="平台"
                label-for="allot_type"
                label-size="sm"
                class="mb-1 required"
        >
          <v-select
                  id="allot_type"
                  :options="getCodeOptions('store_brand')"
                  :clearable="false"
                  v-model = "status_label"
                  class="select-size-sm"
                  @input="changeProductStatus($event)"
                  placeholder="选择平台"
          />
        </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="外部仓库名称"
            label-for="out_warehouse_name"
            label-size="sm"
            class="mb-1 required"
          >
            <b-form-input
              id="out_warehouse_name"
              size="sm"
              v-model="warehouserelation.out_warehouse_name"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>

  </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehouserelationStore from './warehouserelationStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import CompanySelect from '@/views/apps/company/CompanySelect'
import warehouseStore from "@/views/apps/warehouse/warehouseStore";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    CompanySelect,
  },
  data() {
    return {
      id: ref(0),
      storebrandid:"",
      warehouserelation: ref({}),
      channelName:"",
      modalName:"",
      status_label:"",
      statusVal:"",
    }
  },
  methods:{

  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehouserelation')) store.registerModule('warehouserelation', warehouserelationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouserelation')) store.unregisterModule('warehouserelation')
    })

    const edit = function() {
      store.dispatch('warehouserelation/edit', {id: this.name}).then(res => {
        this.warehouserelation = res.data.data
        this.status_label =getCodeLabel('store_brand', res.data.data.store_brand_id)
        this.statusVal = res.data.data.store_brand_id
      })
    }
    const changeProductStatus = function (event) {
      if (event != null) {
        this.status_label = event.label
        this.statusVal = event.value
      }
    }
    const view = function() {
      store.dispatch('warehouserelation/view', {id: this.id}).then(res => {
        this.warehouserelation = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      this.warehouserelation.warehouseId = this.id;
      this.warehouserelation.storeBrandId  = this.statusVal;
      this.warehouserelation.store_brand_id  = this.statusVal;
      this.warehouserelation.whId=this.name;
      if(this.warehouserelation.storeBrandId == undefined || this.warehouserelation.storeBrandId == null || this.warehouserelation.store_brand_id == undefined || this.warehouserelation.store_brand_id == null){
        toast.error("请选择平台")
        return;
      }
      if(this.warehouserelation.out_warehouse_name == undefined || this.warehouserelation.out_warehouse_name == null){
        toast.error("请输入外部仓库名称")
        return;
      }

      store.dispatch('warehouserelation/save', this.warehouserelation).then(res => {
        if(res.data.code==0){
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-warehouserelation-list',query:{id:this.id}});
        }else{
          toast.error(res.data.data)
        }

      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      changeProductStatus,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.name = this.$route.query.companyName || null;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
